import useMatchesData from '../CustomHooks/useMatchesData'

const useIsAuthenticated = () => {
    const data = useMatchesData('root')

    if (data.isAuthenticated) {
        return true
    }
    return false
}

export default useIsAuthenticated