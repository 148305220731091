const config_dev = {
    WEBSITE_BASE_URL: 'http://localhost:3000',
    API_BASE_URL: 'http://localhost:4500',
    AUTH_PAGE_URL: 'https://accounts.daddysteach.com',

    //ASK_HERO_BACKGROUND_IMAGE: 'https://i.ibb.co/stNKKG8/classroom-2787754-1280.jpg',
    //SCHOLARSHIPS_HERO_BACKGROUND_IMAGE: 'https://i.ibb.co/stNKKG8/classroom-2787754-1280.jpg',

    ASK_HERO_BACKGROUND_IMAGE: 'https://media.daddysteach.com/main/ask_hero_background_image.jpg',
    SCHOLARSHIPS_HERO_BACKGROUND_IMAGE: 'https://media.daddysteach.com/main/ask_hero_background_image.jpg',
    POLICY_HERO_BACKGROUND_IMAGE: 'https://media.daddysteach.com/main/policy_hero_background_image.jpg',

    RECAPTCHA_SITE_KEY: '6LdPfhonAAAAAMUZdAaDkbupBK3a1dzoSuUZm6Cv',

    STRIPE_PUBLIC_KEY: 'pk_test_51NdPEIILT9U51gs5iC3cZwME4Yr0QF6QqAIp942LGTITotblHcPapvlDnrxllV2u9BTls7SQ6t4EBBsGkK3W7C8V00u54l5hRZ'
}

const config = {
    WEBSITE_BASE_URL: 'https://daddysteach.com',
    API_BASE_URL: 'https://api.daddysteach.com',
    AUTH_PAGE_URL: 'https://accounts.daddysteach.com',

    //ASK_HERO_BACKGROUND_IMAGE: 'https://i.ibb.co/stNKKG8/classroom-2787754-1280.jpg',
    //SCHOLARSHIPS_HERO_BACKGROUND_IMAGE: 'https://i.ibb.co/stNKKG8/classroom-2787754-1280.jpg',

    ASK_HERO_BACKGROUND_IMAGE: 'https://media.daddysteach.com/main/ask_hero_background_image.jpg',
    SCHOLARSHIPS_HERO_BACKGROUND_IMAGE: 'https://media.daddysteach.com/main/ask_hero_background_image.jpg',
    POLICY_HERO_BACKGROUND_IMAGE: 'https://media.daddysteach.com/main/policy_hero_background_image.jpg',

    RECAPTCHA_SITE_KEY: '6LfIui4oAAAAACesZlxscnTis-oMkJ9GyUIenlhW',

    STRIPE_PUBLIC_KEY: ''
}

export default config