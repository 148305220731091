import { useMemo } from 'react'
import { useMatches } from '@remix-run/react'

const useMatchesData = (id) => {
    const matchingRoutes = useMatches()

    const route = useMemo(
        () => matchingRoutes.find((route) => route.id === id),
        [matchingRoutes, id]
    )

    return route?.data;
}

export default useMatchesData